import { useState } from "react";
import "./App.css";

function getRandomInt(a, b) {
  return Math.floor(Math.random() * (b + 1 - a)) + a;
}

function getRGBString(r, g, b) {
  return "rgb(" + r + "," + g + "," + b + ")";
}

function getHSLString(h, s, l) {
  return `hsl(${h},${s}%,${l}%)`;
}

function mutateColor(x, d) {
  let a = (x - d + 360) % 360;
  let b = (x + d + 360) % 360;
  if (getRandomInt(0, 1) === 0) {
    return a;
  }

  return b;
}

function genState(row, col, d) {
  const rowCnt = row;
  const colCnt = col;
  const colorH = getRandomInt(0, 359);
  const colorS = 100;
  const colorL = getRandomInt(30, 70);
  const rowTarget = getRandomInt(0, 9);
  const colTarget = getRandomInt(0, 9);
  const targetColorH = mutateColor(colorH, d);
  // const targetColorS = mutateColor(colorS, d);
  return {
    rowCnt: rowCnt,
    colCnt: colCnt,
    colorH: colorH,
    colorS: colorS,
    colorL: colorL,
    rowTarget: rowTarget,
    colTarget: colTarget,
    targetColorH: targetColorH,
    targetColorS: colorS,
    targetColorL: colorL,
    d: d,
    findit: false,
  };
}

function App() {
  const [state, setState] = useState(genState(10, 10, 50));
  const handleClick = function () {
    setState({ ...state, findit: true });
  };
  const doReset = function () {
    setState(genState(10, 10, state.d));
  };

  const doharder = function () {
    let old_d = state.d;
    let d = old_d - 5;
    d = Math.max(d, 1);
    setState(genState(10, 10, d));
  };

  const doeaisier = function () {
    let old_d = state.d;
    let d = old_d + 5;
    d = Math.min(d, 100);
    setState(genState(10, 10, d));
  };

  const doShowAnswer = function () {
    setState({ ...state, findit: !state.findit });
  };

  const onDifficulityChange = function (e) {
    setState(genState(10, 10, parseInt(e.target.value)));
  };

  const addDifficulityByOne = function(){
    if(state.d<180)
      setState(genState(10,10,state.d+1))
  }

  const subDifficulityByOne = function(){
    if(state.d>1)
      setState(genState(10,10,state.d-1))
  }


  const checkIcon = (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-8 h-8  lg:w-10 lg:h-10"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m4.5 12.75 6 6 9-13.5"
        />
      </svg>
    </>
  );

  let t = [];
  for (let i = 0; i < state.rowCnt; i++) {
    let rowElems = [];
    for (let j = 0; j < state.colCnt; j++) {
      if (i === state.rowTarget && j === state.colTarget) {
        rowElems.push(
          <>
            <div
              className=" w-8 h-8  lg:w-10 lg:h-10 m-1 rounded"
              onClick={handleClick}
              style={{
                "background-color": getHSLString(
                  state.targetColorH,
                  state.targetColorS,
                  state.targetColorL
                ),
              }}
            >
              {state.findit ? checkIcon : null}
            </div>
          </>
        );
        continue;
      }
      rowElems.push(
        <>
          <div
            className="w-8 h-8 lg:w-10 lg:h-10 m-1 rounded"
            style={{
              "background-color": getHSLString(
                state.colorH,
                state.colorS,
                state.colorL
              ),
            }}
          ></div>
        </>
      );
    }
    t.push(
      <>
        <div className="flex flex-row">{rowElems}</div>
      </>
    );
  }
  return (
    <div className="p-10 mx-auto my-10">
      {t}
      <div className="flex flex-row justify-around m-5">
        <p>current difficulty:{state.d}</p>
      </div>
      <div className="p-10 flex flex-row gap-3">
        <input
          type="range"
          min={1}
          max={180}
          value={state.d}
          className="range range-primary flex-1"
          onChange={onDifficulityChange}
        />
        <button onClick={subDifficulityByOne}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
            />
          </svg>
        </button>
        <button onClick={addDifficulityByOne}>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75 12 3m0 0 3.75 3.75M12 3v18" />
</svg>

        </button>
      </div>
      <div className="flex flex-row justify-around m-5">
        <button className="btn btn-primary" onClick={doReset}>
          reset
        </button>
        <button className="btn btn-primary " onClick={doharder}>
          harder
        </button>
        <button className="btn btn-primary" onClick={doeaisier}>
          eaisier
        </button>
        <button className="btn btn-primary" onClick={doShowAnswer}>
          showAnswer
        </button>
      </div>
    </div>
  );
}

export default App;
